<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <FileUpload
            name="fileSelect"
            url="./upload.php"
            :showUploadButton="false"
            @upload="onFileUpload"
            @select="onFileSelect"
            @clear="onCancel"
            @remove="onCancel"
            choose-label="Выбрать файл"
            cancel-label="Отмена"
            accept=".csv,.xlsx"
            :maxFileSize="100_000_000"
            :file-limit="1"
        />
			</div>
		</div>
	</div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import * as ExcelJS from "exceljs";
import { getFileExtension } from "@/utils/util";
import { ReadableWebToNodeStream } from "readable-web-to-node-stream";
import { inject } from 'vue'
import {routes} from "@/router";

export default {
  components: {FileUpload},
  data() {
    return {
    }
  },
  setup() {
    const rawAnalysisData = inject('rawAnalysisData')
    const clearAnalysisData = inject('clearAnalysisData')
    const loadRawData = inject('loadRawData')
    const setData = inject('setData')
    const setTable = inject('setTable')
    const hideSpinner = inject('hideSpinner')
    const showSpinner = inject('showSpinner')

    return {
      rawAnalysisData,
      clearAnalysisData,
      loadRawData,
      setData,
      setTable,
      hideSpinner,
      showSpinner,
    }
  },
  methods: {
    onFileUpload(event) {
      console.log('onUpload', event)
    },
    onFileSelect(event) {
      const file = event.files[0]
      this.readFileLocal(file)
    },
    onCancel() {
      this.clearAnalysisData()
      this.hideSpinner()
    },
    async readFileLocal(file) {
      this.showSpinner()

      const workbook = new ExcelJS.Workbook();

      if (getFileExtension(file.name) === 'xlsx') {
        await workbook.xlsx.load(file.arrayBuffer())
      } else if (getFileExtension(file.name) === 'csv') {
        const stream = new ReadableWebToNodeStream(
            file.stream()
        );
        await workbook.csv.read(stream, {
          parserOptions: {
            delimiter: ';'
          }
        })
      } else {
        this.onCancel()
        return
      }

      const ws = workbook.worksheets[0];
      // запускаем читалки параллельно
      const toShowPromise = this.readToShow(ws)
      const readDataPromise = this.readData(ws)
      const readTablePromise = this.readTable(ws)

      // ждём завершения чтения
      const asyncResults = await Promise.allSettled([toShowPromise, readDataPromise, readTablePromise])
      const errorReason = this.hasRejectedPromises(asyncResults)
      if (errorReason) {
        this.$toast.add({
          severity:'error', summary: 'Ошибка чтения файла', detail: errorReason, life: 10000
        });
        console.error('error of file reading: ', errorReason)
        this.onCancel()
        return
      }

      // проверяем дубликаты и сбрасываем файл, если находим
      const targets = this.rawAnalysisData.sheet.map(el => el[Object.keys(el)[0]]).filter(el => el);
      const doubles = this.hasDoubles(targets)
      if (doubles) {
        this.$toast.add({
          severity:'error', summary: 'Файл содержит дубликаты строк', detail: `Удалите дубликат:\n${doubles}`, life: 10000
        });
        console.error('doubles of string:', doubles)
        this.onCancel()
        return
      }

      await this.$router.push(routes[0].children[1].path)

      // всё вычислили, спиннер можно остановить
      this.hideSpinner()
    },
    async readToShow(ws) {
      const rows = ws.getRows(1, ws.rowCount)

      let headers = []
      let data = []
      const count = rows[0].values.length
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          const dateFormatter = new Intl.DateTimeFormat('default', {month: 'short', year: 'numeric'})
          for (let j = 1; j < count; j++) {
            const header = rows[i].values[j]
            if (header === null || header === undefined) {
              headers[j-1] = ' '
            } else if (header instanceof Date) {
              headers[j-1] = dateFormatter.format(header)
            } else {
              headers[j-1] = header + ''
            }
          }
        } else {
          let tmp = {}
          for (let j = 1; j < count; j++) {
            if (j < 5) {
              tmp[headers[j-1]] = (rows[i].values[j] === null || rows[i].values[j] === undefined)
                  ? ''
                  : ('' + rows[i].values[j])
            } else {
              tmp[headers[j-1]] = (rows[i].values[j] === null || rows[i].values[j] === undefined)
                  ? ((rows[i].values[1] === null || rows[i].values[1] === undefined) ? '' : '0')
                  : '' + ((typeof rows[i].values[j] === 'object') ? rows[i].values[j].result : rows[i].values[j])
            }
          }
          data.push(tmp)
        }
      }

      this.loadRawData(headers, data)
    },
    async readData(ws) {
      const cols = ws.columns
      let data = {}
      for (let i = 2; i < cols[0].values.length; i++) {
        data[cols[0].values[i]] = {
          id: cols[1].values[i],
          sign: cols[2].values[i],
          lag: cols[3].values[i],
          type: "percent_to_prev",
        }
      }
      this.setData(data)
    },
    async readTable(ws) {
      const rows = ws.getRows(1, ws.rowCount)
      let table = {}
      const count = rows[0].values.length
      for (let i = 1; i < rows.length; i++) {
        const rowName = rows[i].values[1]
        let tmp = {}
        for (let j = 5; j < count; j++) {
          let currentValue = (typeof rows[i].values[j] === 'object')
              ? rows[i].values[j].result
              : rows[i].values[j]

          if (typeof currentValue === 'string') {
            const parsedValue = parseFloat(currentValue)

            if (isNaN(parsedValue)) {
              throw new Error(`Некорректное значение в строке "${rows[i].values[1]}": ${currentValue}`)
            }

            this.$toast.add({
              severity:'warn',
              summary: 'Автоматическое преобразование',
              detail: `Проверьте значение в строке "${rows[i].values[1]}": ${parsedValue}`,
              life: 20000
            });
            console.warn('autocasting: ', `${currentValue} -> ${parsedValue}`)

            currentValue = parsedValue
          }

          tmp[rows[0].values[j]] = currentValue
        }
        table[rowName] = tmp
      }

      this.setTable(table)
    },
    hasDoubles(arr) {
      while (arr.length) {
        const checked = arr.pop()
        if (arr.includes(checked)) {
          return checked
        }
      }
      return false
    },
    hasRejectedPromises(arr) {
      return arr.find(res => res.status === "rejected")?.reason
    },
  }
}
</script>

<style scoped>

</style>
